.App {
  /* text-align: center; */
  /* display: flex;
  flex-direction: column; */
  background-color: #E1E1E1;
  height: 115vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ctnLine1 {
  background-color: #3C4043;
  height: 32px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 10px 0;
}

.title_style_1 {
  font-family: 'Arial';
  font-size: 18px;
  font-weight: 700;
  color: white;
}

.ctnLine2 {
  background-color: #C1C1C1;
  /* height: 52px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0 0 0;
}

.ctnTitleInfoEmu {
  height: 32px;
  display: flex;
  background-color: #C1C1C1;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  color: #3C4043;
}

.ctnInfoEmu {
  font-size: 16px;
  font-weight: 700;
  color: #3C4043;
  height: 32px;
  display: flex;
  background-color: #E8E8E8;
  justify-content: center;
  align-items: center;
}

.ctnButtonControl {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: pink; */
}

.buttonControlStyle {
  /* padding: 44px; */
  height: 132px;
  width: 210px;
  background: linear-gradient(180deg, #C1C1C1 0%, #949494 100%);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.45);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-bottom: 12px;
  cursor: pointer;
}

.textButtonControlStyle {
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.4);
  font-size: 32px;
  font-weight: 600;
  color: #474A4E;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.4);
  margin-bottom: 25px;
}

.circleButton {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #5B6166 0%, #3C4043 100%);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.circleButtonClick {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #5CF300 0%, #44B400 100%);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.ctnSelectionView {
  /* margin-top: 57px; */
  display: flex;
  justify-content: center;
}

.ctnRotation {
  margin: 68px 88px 0 88px;
  height: 72px;
  width: 100%;
  background-color: #353535;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.rotationTextStyle {
  font-size: 24px;
  font-weight: 600;
  color: #8D8D8D;
  cursor: pointer;
}

.rotationTextClickStyle {
  font-size: 24px;
  font-weight: 600;
  color: #A8F406;
  cursor: pointer;
}

.deviderStyle {
  border: 0.5px solid #B6B6B6C7;
  height: 40px;
}

.ctnVariant {
  margin: 68px 88px 0 88px;
  height: 72px;
  width: 404px;
  background-color: #353535;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.ctnItemPosition {
  height: 140px;
  background-color: #C1C1C1;
  padding: 16px 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.titlePositionStyle {
  font-size: 26px;
  font-weight: 600;
  color: #3C4043;
}

.ctnPostion {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.ctnSubLaying {
  margin: 68px 88px 0 88px;
  height: 72px;
  width: 100%;
  background-color: #353535;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.ctnSubStanding {
  margin: 68px 88px 0 88px;
  height: 72px;
  width: 468px;
  background-color: #353535;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.ctnSubPositionView {
  display: flex;
  justify-content: center;
}

.ctnBottom {
  margin-top: 20px;
  display: flex;
  margin-left: 56px;
  margin-right: 56px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.ctnLogo {
  display: flex;
  align-items: center;
}

.classPin {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
}

.textInputBreed {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  padding-left: 16px;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-right: 16px;
  margin-left: 8px;
}

.textInputAge {
  background-color: white;
  width: 66px;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  padding-left: 16px;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-right: 16px;
  margin-left: 8px;
}
.textInputWeight {
  background-color: white;
  width: 96px;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  padding-left: 16px;
  padding-top: 19px;
  padding-bottom: 19px;
  padding-right: 16px;
  margin-left: 8px;
}

.buttonSave {
  background-color: #4C06B7;
  border-radius: 8px;
  height: 60px;
  width: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}

.buttonExport {
  border-radius: 8px;
  border: 1px solid #4C06B7;
  height: 60px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}

.container {
  background-color: #E1E1E1;
  height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ctnParameter {
  display: flex;
  flex-direction: 'row';
  display: 'flex';
}

.textParamStyle {
  font-size: 16px;
  font-weight: 700;
  height: 22px;
  display: flex;
  background-color: #E8E8E8;
  justify-content: center;
  align-items: center;
  color: #3C4043;
}

.textParamStyle2 {
  font-size: 16px;
  font-weight: 700;
  height: 22px;
  display: flex;
  background-color: rgba(195, 195, 195, 0.34);
  justify-content: center;
  align-items: center;
  color: #3C4043;
  border-bottom: 1px solid #C1C1C1;
  border-top: 1px solid #C1C1C1;
}

.ctnTitleParam {
  height: 32px;
  display: flex;
  background-color: #3C4043;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
}

.borderStyle {
  border: 1px solid #434343;
  height: 115px;
  overflow: auto;
  margin-top: 16px;
  scroll-behavior: smooth;
}

.ctnLastestParam {
  height: 32px;
  display: flex;
  background-color: #BBBBBB;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  color: #BB0B00;
}
